import React from 'react'
import './productChatItem.css'
import { useNavigate } from 'react-router-dom'
// import { useMyContext } from '../../../context/langugaeContext'
import { useTranslation } from 'react-i18next'
export default function ProductChatItem({ item, showChatHandler }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  // const { language } = useMyContext();

  const goToProductHandler = () => {
    if (item?.link) {
      window.open(item.link, "_blank");
    } else {
      return false;
    }
  };
  

  return (
    <div
      className={`productchat`}
      onClick={goToProductHandler}
    >
      <div className="d-flex align-items-center gap-2">
        <div className="img-chat-wrapp">
          <img
            src={`https://api.nobinco.com/chat${item.image}`}
            alt="image"
          />
        </div>
        <span className="product-chat-name">{item.name}</span>
      </div>

    </div>
  );
}
