import Styled from "./Footer.module.css"

const Footer = () => {
    return (
        <>
            <div className={Styled.FooterStyle}>

                <div className={Styled.FooterUrlDiv}>
                    <a
                        className={Styled.UrlStyle}
                        href="https://www.golnoor.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.golnoor.com
                    </a>
                </div>

            </div>
        </>
    )
}


export default Footer