import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { TabProvider } from './context';
import Home from './pages/Home/Home';
import ProductPage from './pages/ProductPage/ProductPage';
import CategoryPage from './pages/CategoryPage/CategoryPage';

function App() {
  return (
    <>
      <TabProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/category/:id" element={<CategoryPage />} /> */}
          <Route path="/:productName" element={<ProductPage />} />
        </Routes>
      </TabProvider>
    </>
  );
}

export default App;
