import { useContext } from "react"
import Styled from "./Home.module.css"
import { TabContext } from "../../context"
import CategoryBox from "../../components/CategoryBox/CategoryBox"

const Home = () => {


    return (
        <>
            <CategoryBox />
        </>
    )
}


export default Home