import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const TabContext = createContext();

export const TabProvider = ({ children }) => {
    const [products, setProducts] = useState()
    const [allProducts, setAllProducts] = useState()
    const [tab, setTab] = useState()
    const [productDetail, setProductDetail] = useState(() => {
        const storedProduct = localStorage.getItem("productDetail");
        return storedProduct ? JSON.parse(storedProduct) : null;
    })
    const [loading, setLoading] = useState(false)


    const getProducts = async () => {
        try {
            setLoading(true)
            const response = await axios.get("https://xrpanel.ariisco.com/items/categories/?fields=*,products.*&filter[factoryId]=1")

            if (response.status === 200) {
                setProducts(response.data)
                setLoading(false)
            }
        }
        catch {

        }
    }

    useEffect(() => {
        getProducts()
    }, [])




    useEffect(() => {
        if (products) {
            console.log("ok");
            products?.data?.forEach((item) => {
                setAllProducts((prevProducts) => {
                    // اطمینان از اینکه prevProducts یک آرایه است
                    const currentProducts = Array.isArray(prevProducts) ? prevProducts : [];

                    // ترکیب محصولات جدید و قدیمی و حذف آیتم‌های تکراری بر اساس productCode
                    const updatedProducts = [
                        ...currentProducts,
                        ...item.products
                    ];

                    // فیلتر کردن تکراری‌ها با استفاده از productCode
                    const uniqueProducts = updatedProducts.filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => (
                                t.id === value.id
                            ))
                    );

                    return uniqueProducts;
                });
            });
        }
    }, [products]);





    useEffect(() => {
        console.log(allProducts)
    }, [allProducts])

    return (
        <TabContext.Provider value={
            {
                products,
                allProducts,
                tab,
                setTab,
                productDetail,
                setProductDetail,
                loading
            }
        }>
            {children}
        </TabContext.Provider>
    )
}