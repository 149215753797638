import { ArchiveTick, TickCircle } from "iconsax-react"
import Styled from "./CategoryCard.module.css"

const CategoryCard = (props) => {


    return (
        <>
            <div className={`${props.status ? Styled.ParentBoxActive : Styled.ParentBox}`} onClick={props.onClick}>
                {
                    props.status ?
                        <div className={Styled.Status}>
                            <TickCircle size="32" color="white" />
                        </div>
                        :
                        null
                }
                <div className={Styled.ImageBox}>
                    <img src={`https://xrpanel.ariisco.com/assets/${props.data?.image ? props.data?.image : props.data?.posterImage}`} alt="" />
                </div>
                <span>{props.data?.name}</span>
            </div>
        </>
    )
}


export default CategoryCard