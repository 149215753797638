import Styled from "./Loading.module.css"

const Loading = () => {
    return (
        <>
            <div className={Styled.LoadingPage}>
                <div className={Styled.loader}></div>
            </div>
        </>
    )
}

export default Loading

