import { useContext, useEffect, useState } from "react"
import CategoryCard from "../CategoryCard/CategoryCard"
import Styled from "./CategoryBox.module.css"
import { TabContext } from "../../context"
import { useNavigate } from "react-router-dom"
import Header from "../Header/Header"
import TabBox from "../TabBox/TabBox"
import Footer from "../Footer/Footer"
import Loading from "../loading/Loading"
import Chat from "../Chat/Chat"



const CategoryBox = () => {
    const { products, tab, setProductDetail, loading } = useContext(TabContext)
    const [selectedProducts, setSelectedProducts] = useState()
    const [ids, setIds] = useState()
    const [filterProducts, setFilterProducts] = useState()
    const navigate = useNavigate()

    const getDeviceName = () => {
        const userAgent = navigator.userAgent;

        if (/windows/i.test(userAgent)) return "Windows";
        if (/macintosh|mac os x/i.test(userAgent)) return "IOS";
        if (/iphone/i.test(userAgent)) return "IOS";
        if (/ipad/i.test(userAgent)) return "IOS";
        if (/android/i.test(userAgent)) return "Android";
        if (/linux/i.test(userAgent)) return "Linux";

        return "Unknown";
    };

    const getLocation = async () => {
        try {
            const response = await fetch("https://freeipapi.com/api/json/");
            const locationData = await response.json();

            return {
                city: locationData.cityName || "Unknown",
                country: locationData.countryName || "Unknown",
                ip: locationData.ipAddress || "Unknown",
            };
        } catch (error) {
            console.error("❌ Error fetching location data:", error);
            return { city: "Unknown", country: "Unknown", ip: "Unknown" };
        }
    };

    const sendLog = async (productId, productName, categoryName) => {
        try {
            const { city, country, ip } = await getLocation();
            const device = getDeviceName();

            await fetch("https://xrpanel.ariisco.com/items/logs", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    productId,
                    categoryName,
                    productName,
                    city,
                    country,
                    ip,
                    device,
                }),
            });

            console.log("✅ Log sent successfully!");
        } catch (error) {
            console.error("❌ Error:", error);
        }
    };

    const HandleCLick = (categoryname) => {


        navigate(`/${categoryname}`)

    }

    useEffect(() => {
        const selectedProducts = products?.data?.filter(item => item.id == tab)
        setSelectedProducts(selectedProducts)

    }, [tab])


    useEffect(() => {
        setIds(selectedProducts?.[0]?.children)
        console.log(selectedProducts)
    }, [selectedProducts])


    useEffect(() => {

        const filteredProducts = products?.data.filter(product =>
            ids?.includes(product.id)
        );
        setFilterProducts(filteredProducts);
        console.log(tab)
    }, [ids]);

    // useEffect(() => {
    //     console.log(filterProducts)
    // }, [ids])

    const handleProductDetail = (id, slug, item) => {
        navigate(`/${slug}`);
        setProductDetail(item);
        localStorage.setItem("productDetail", JSON.stringify(item)); // ذخیره در لوکال استوریج
        sendLog(id, item?.products?.[0]?.name, item.name);
    };


    return (

        <>
            {
                loading ?
                    <Loading />
                    :
                    <div className={Styled.ParentBox}>
                        <Header />
                        <TabBox />
                        <div className={Styled.List}>
                            {
                                filterProducts ?
                                    filterProducts.map((item) => (
                                        <CategoryCard data={item} onClick={() => handleProductDetail(item.id, item.products?.[0]?.slug, item)} />
                                    ))
                                    :
                                    null
                            }
                        </div>
                        <Chat />
                        <Footer />


                    </div>
            }

        </>
    )
}

export default CategoryBox