import Styled from "./Header.module.css"

const Header = () => {
    return (
        <>
            <div className={Styled.Header}>
                <div className={Styled.TextBox}>
                    <span className={Styled.S1}>GOLNOOR</span>
                    <span className={Styled.S2}>AR</span>
                </div>
                <div className={Styled.LogoBox}>
                    <span>Golnoor</span>
                </div>
            </div>
        </>
    )
}

export default Header