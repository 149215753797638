import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';
import Message from '../Message/Message';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { BiSolidSend } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
// import { useMyContext } from '../../../context/langugaeContext';
import axios from 'axios';
import { div } from 'three/tsl';
import { ArrowLeft2, Book1, BoxSearch } from 'iconsax-react';

const Chat = () => {
    const { t } = useTranslation();
    const [showChat, setShowChat] = useState(false);
    // const { language } = useMyContext();
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [sessioId, setSessionId] = useState("");
    const [disableInput, setDisableInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const chatContentRef = useRef(null);
    const inputRef = useRef(null);
    const [responseCount, setResponseCount] = useState(0);
    const [maxMessage, setMaxMessage] = useState(70)
    const [maxLengthChat, setMaxLengthChat] = useState(false)
    const [windowWidth, setWindowWidth] = useState()
    const [chatTab, setChatTab] = useState(1)
    const [key, setKey] = useState()

    const showChatHandler = () => {
        setShowChat((prev) => {
            const newValue = !prev;
            // setActiveChat(newValue);
            return newValue;
        });
    };

    const sendMessage = async () => {
        if (message.trim() === "") return;

        if (responseCount === maxMessage) {
            setMaxLengthChat(true);
            return;
        }

        else {
            const newMessage = {
                id: crypto.randomUUID(),
                isai: false,
                text: message
            };
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setMessage("");
            setDisableInput(true);
            setLoading(true);

            const headers = {
                'X-Language': "fa",
                'fac-key': key
            };

            try {
                const body = {
                    query: message,
                    ...(sessioId && { session_id: sessioId })
                };
                const res = await axios.post(
                    `https://api.nobinco.com/chat/product/productinfochatIP/`,
                    body,
                    {
                        headers,
                    }
                );
                if (res.status === 201 || res.status === 200) {
                    setDisableInput(false);
                    setLoading(false);
                    if (res.data.session_id) {
                        setSessionId(res.data.session_id);
                    }

                    if (res.data.request_limit) {
                        setMaxMessage(res.data.request_limit);
                    }

                    const messageAi = {
                        id: crypto.randomUUID(),
                        isai: true,
                        text: res.data.ai_assistant
                    };

                    setMessages((prevMessages) => [...prevMessages, messageAi]);

                    setResponseCount((prevCount) => prevCount + 1);

                    if (res.data.seggestion_list) {
                        const messageAi = {
                            id: crypto.randomUUID(),
                            isai: true,
                            images: res.data.seggestion_list
                        };
                        setMessages((prevMessages) => [...prevMessages, messageAi]);
                    }

                }
            } catch (error) {
                setLoading(false);
                setDisableInput(false);
                const errorMessage = {
                    id: crypto.randomUUID(),
                    isai: true,
                    text: t('errorchat'),
                    isError: true
                };
                setMessages((prevMessages) => [...prevMessages, errorMessage]);
            }
        }

    };


    const refreshChat = () => {
        setMessage("")
        setMessages("")
        setSessionId("")
        setMaxLengthChat(false)
        setMaxMessage(70)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;

        if (true) {
            const persianRegex = /^[آ-ی۰-۹\s]*$/;
            if (persianRegex.test(inputValue)) {
                setMessage(inputValue);
            }
        }
    };

    useEffect(() => {
        if (!loading && !disableInput) {
            inputRef.current?.focus();
        }
    }, [messages, loading, disableInput]);

    useEffect(() => {
        const chatContentElement = chatContentRef.current;

        if (chatContentElement) {
            const isContentOverflowing =
                chatContentElement.scrollHeight > chatContentElement.clientHeight;

            if (isContentOverflowing) {
                chatContentElement.scrollTo({
                    top: chatContentElement.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }
    }, [messages, maxLengthChat]);

    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    const HandleChat = (tab) => {
        setChatTab(2)
        if (tab == 1) {
            setKey('gol1')
            setMessages([])
        }
        else if (tab == 2) {
            setKey('gol2')
            setMessages([])
        }
    }

    const changeTab = () => {
        setChatTab(1)
        setMessages([])
    }

    return (
        <>
            {
                windowWidth >= 767 ?
                    <div className='desk-chat'>
                        <div
                            className={`chatbot-container ${showChat && 'active-chatbot'}`}
                        >
                            <div className='chat-header'>
                                {
                                    chatTab == 1 ?

                                        <CloseIcon className='close-chat'  onClick={showChatHandler} />
                                        :
                                        <ArrowLeft2 size="32" color="white" onClick={() => changeTab()} />
                                }

                                <>
                                    <span className='title-chat-fa'>
                                        دستیار هوشمند گلنور
                                        <img
                                            className='icon-chatbot'
                                            src='/Frame 5.png'
                                            alt='logo'
                                        />
                                    </span>

                                </>
                            </div>
                            <div className='chatbot-body'>
                                {
                                    chatTab == 1 ?
                                        <div className='category-box'>
                                            <div className='category-item' onClick={() => HandleChat(2)}>
                                            <Book1 size="32" color="white" />
                                                <span>راهنمای گلنور</span>
                                            </div>
                                            <div className='category-item' onClick={() => HandleChat(1)}>
                                                <BoxSearch size="32" color="white" />

                                                <span>دستیار انتخاب محصول</span>
                                            </div>
                                        </div>
                                        :
                                        <div className='chat-content' ref={chatContentRef}>
                                            {messages.length > 0 &&
                                                messages.map((message) => (
                                                    <Message
                                                        key={message.id}
                                                        message={message}
                                                        showChatHandler={showChatHandler}
                                                    />
                                                ))}

                                            {loading && (
                                                <div className='loading-chat'>
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                </div>
                                            )}
                                            {
                                                maxLengthChat &&
                                                <>
                                                    <div className={`message_wrapper_ai`} >
                                                        <p className={`chat-contant-ai`}>
                                                            {t("textmaxchat")}
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-center mt-5'>
                                                        <button className='btn-maxlenght' onClick={refreshChat}>{t("newChat")}</button>
                                                    </div>

                                                </>
                                            }
                                        </div>
                                }
                            </div>
                            {
                                chatTab == 1 ?
                                    <></>
                                    :
                                    <div className={`chat-bottom`}>
                                        <div className='chatbot-actions'>
                                            <input
                                                type='text'
                                                value={message}
                                                onChange={handleChange}
                                                className={`input-chat ${disableInput && 'disable-input-chat'}`}
                                                autoComplete='false'
                                                placeholder="سوال خود را بپرسید..."
                                                onKeyDown={handleKeyDown}
                                                disabled={disableInput || maxLengthChat}
                                                maxLength={200}
                                                ref={inputRef}
                                            />
                                            <BiSolidSend
                                                className={`send-icon`}
                                                onClick={sendMessage}
                                            />
                                        </div>
                                        <p className='text-bottom'>Powered By NOBINCO</p>
                                    </div>
                            }

                        </div>
                        <div
                            onClick={() => setShowChat(prev => !prev)}
                            className='chatboticon'
                        >
                            <img src='/images/chatbot.png' alt='icon_chat' />
                        </div>
                    </div> :
                    <div className='mobile-chat'>
                        <div
                            className={`chatbot-container ${showChat && 'active-chatbot'}`}
                        >
                            <div
                                className={`chatbot-header`}
                            >
                                <div className='chat-header'>
                                    {
                                        chatTab == 1 ?

                                            <CloseIcon className='close-chat' onClick={showChatHandler} />
                                            :
                                            <ArrowLeft2 size="32" color="white" onClick={() => setChatTab(1)} />
                                    }

                                    <>
                                        <span className='title-chat-fa'>
                                            دستیار هوشمند گلنور
                                            <img
                                                className='icon-chatbot'
                                                src='/Frame 5.png'
                                                alt='logo'
                                            />
                                        </span>

                                    </>
                                </div>
                            </div>
                            <div className='chatbot-body'>
                                {
                                    chatTab == 1 ?
                                        <div className='category-box'>
                                            <div className='category-item' onClick={() => HandleChat(2)}>
                                            <Book1 size="32" color="white" />

                                                <span>راهنمای گلنور</span>
                                            </div>
                                            <div className='category-item' onClick={() => HandleChat(1)}>
                                                <BoxSearch size="32" color="white" />

                                                <span>دستیار انتخاب محصول</span>
                                            </div>
                                        </div>
                                        :
                                        <div className='chat-content' ref={chatContentRef}>
                                            {messages.length > 0 &&
                                                messages.map((message) => (
                                                    <Message
                                                        key={message.id}
                                                        message={message}
                                                        showChatHandler={showChatHandler}
                                                    />
                                                ))}

                                            {
                                                maxLengthChat &&
                                                <>
                                                    <div className={`message_wrapper_ai`} >
                                                        <p className={`chat-contant-ai`}>
                                                            {t("textmaxchat")}
                                                        </p>
                                                    </div>
                                                    <div className='d-flex justify-content-center mt-5'>
                                                        <button className='btn-maxlenght' onClick={refreshChat}>{t("newChat")}</button>
                                                    </div>
                                                </>
                                            }

                                            {loading && (
                                                <div className='loading-chat'>
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                    <div className='dot'></div>
                                                </div>
                                            )}
                                        </div>
                                }

                            </div>
                            {
                                chatTab == 1 ?
                                    <></>
                                    :
                                    <div className={`chat-bottom`}>
                                        <div className='chatbot-actions'>
                                            <input
                                                type='text'
                                                value={message}
                                                onChange={handleChange}
                                                className={`input-chat ${disableInput && 'disable-input-chat'}`}
                                                autoComplete='false'
                                                placeholder='سوال خود را بپرسید...'
                                                onKeyDown={handleKeyDown}
                                                disabled={disableInput || maxLengthChat}
                                                maxLength={200}
                                                ref={inputRef}
                                            />
                                            <BiSolidSend
                                                className={`send-icon`}
                                                onClick={sendMessage}
                                            />
                                        </div>
                                        <p className='text-bottom'>Powered By NOBINCO</p>
                                    </div>
                            }
                        </div>
                        <div
                            onClick={showChatHandler}
                            className='chatboticon'
                        >
                            <img src='/images/chatbot.png' alt='icon_chat' />
                        </div>
                    </div>
            }
        </>
    );
}



export default Chat

// https://api.nobinco.com/chat/product/productinfochatIP/