import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabContext } from "../../context";
import Styled from "./ProductPage.module.css";
import '@google/model-viewer';
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import Header from "../../components/Header/Header";
import TabBox from "../../components/TabBox/TabBox";
import Footer from "../../components/Footer/Footer";
import Loading from "../../components/loading/Loading";

const ProductPage = () => {
    const { products, allProducts, productDetail, setProductDetail, loading } = useContext(TabContext)
    const { productName } = useParams();
    const navigate = useNavigate()
    const decodedProductName = decodeURIComponent(productName).replace(/-/g, " ");
    function startAR() {
        const modelViewer = document.querySelector("model-viewer");
        if (modelViewer) {
            modelViewer.activateAR();
        } else {
            console.error("Model Viewer not found!");
        }
    }
    const [category, setCategory] = useState(null);

    const [product, setProduct] = useState()
    const [selectCategory, setSelectCategory] = useState()

    const params = useParams()

    const getDeviceName = () => {
        const userAgent = navigator.userAgent;

        if (/windows/i.test(userAgent)) return "Windows";
        if (/macintosh|mac os x/i.test(userAgent)) return "Mac";
        if (/iphone/i.test(userAgent)) return "iPhone";
        if (/ipad/i.test(userAgent)) return "iPad";
        if (/android/i.test(userAgent)) return "Android";
        if (/linux/i.test(userAgent)) return "Linux";

        return "Unknown";
    };

    // const sendLog = async (productId, productName, categoryName) => {
    //     try {
    //         const script = document.createElement("script");
    //         script.src = `http://ip-api.com/json/?callback=getLocationData`;
    //         document.body.appendChild(script);
    
    //         window.getLocationData = async (locationData) => {
    //             const city = locationData.regionName || "Unknown";
    //             const country = locationData.country || "Unknown";
    //             const ip = locationData.query || "Unknown";
    
    //             const device = getDeviceName();
    
    //             const response = await fetch("https://xrpanel.ariisco.com/items/logs", {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 body: JSON.stringify({
    //                     productId,
    //                     categoryName,
    //                     productName,
    //                     city,
    //                     country,
    //                     ip,
    //                     device,
    //                 }),
    //             });
    
    //             const result = await response.json();
    //             console.log("✅ Response from API:", result);
    //         };
    //     } catch (error) {
    //         console.error("❌ Error:", error);
    //     }
    // };
    
    const getLocation = async () => {
        try {
            const response = await fetch("https://freeipapi.com/api/json/");
            const locationData = await response.json();
    
            return {
                city: locationData.cityName || "Unknown",
                country: locationData.countryName || "Unknown",
                ip: locationData.ipAddress || "Unknown",
            };
        } catch (error) {
            console.error("❌ Error fetching location data:", error);
            return { city: "Unknown", country: "Unknown", ip: "Unknown" };
        }
    };
    
    const sendLog = async (productId, productName, categoryName) => {
        try {
            const { city, country, ip } = await getLocation();
            const device = getDeviceName();
    
            await fetch("https://xrpanel.ariisco.com/items/logs", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    productId,
                    categoryName,
                    productName,
                    city,
                    country,
                    ip,
                    device,
                }),
            });
    
            console.log("✅ Log sent successfully!");
        } catch (error) {
            console.error("❌ Error:", error);
        }
    };
    
    
    useEffect(() => {
        // console.log(allProducts)
        console.log(decodedProductName)
        // چک کردن اینکه آیا products یک آرایه معتبر است

        // جستجوی محصول با نام مشابه decodedProductName
        const foundProduct = allProducts?.find(item => item.name === decodedProductName);

        if (foundProduct) {
            setCategory(foundProduct); // فرض کنید در داده شما فیلدی به نام category وجود دارد
            console.log(foundProduct)
            console.log("ok")



        } else {
            setCategory(null); // در صورتی که محصول پیدا نشود
            console.log("ok")



        }

    }, [decodedProductName, products]);


    useEffect(() => {
        setProduct(productDetail?.products?.[0])
    }, [productDetail])


    useEffect(() => {
        console.log(products)
    }, [products])

    const handleProductDetail = (id, slug, item) => {
        navigate(`/${slug ? slug : item.slug}`)
        setProduct(item)
        setSelectCategory("")
        const categoryName = products?.data?.filter(data => data.id == item.categoryId)
        console.log(slug, item)
        console.log(item)

        sendLog(id , item.name , categoryName?.[0]?.name)


    }

    useEffect(() => {
        console.log("allProducts:", allProducts);
        console.log("productName:", params.productName);

        if (params.productName && allProducts && allProducts.length > 0) {
            const filterProduct = allProducts.filter(item =>
                item.name?.trim().toLowerCase() === params.productName?.trim().toLowerCase()
            );
            setProduct(filterProduct?.[0]);
            const filterCategory = products?.data?.filter(item => item.id == filterProduct?.[0]?.categoryId)
            setSelectCategory(filterCategory)
        } else {
            console.log("No matching products or empty allProducts");
        }

    }, [params, allProducts]);


    useEffect(() => {
        console.log(selectCategory)
    }, [selectCategory])



    useEffect(() => {
        if (!productDetail) {
            const storedProduct = localStorage.getItem("productDetail");
            if (storedProduct) {
                setProductDetail(JSON.parse(storedProduct));
            }
        }
    }, []);

    useEffect(() => {
        if (!productDetail && allProducts) {
            const foundProduct = allProducts.find(item => item.name === decodedProductName);
            if (foundProduct) {
                setProductDetail(foundProduct);
            }
        }
    }, [decodedProductName, allProducts]);


    useEffect(() => {
        console.log(product)
    }, [product])

    return (
        <>
            {
                loading ?
                    <Loading />
                    :
                    <div className={Styled.ProductPage}>
                        <Header />
                        <TabBox id="1" />
                        <div className={Styled.ModelBox}>
                            {
                                product?.usdzModel ?
                                    <model-viewer
                                        style={{ width: '100%', height: '100%' }}
                                        alt="Neil Armstrong's Spacesuit"
                                        src={`https://xrpanel.ariisco.com/assets/${product?.glbModel}`}
                                        ios-src={`https://xrpanel.ariisco.com/assets/${product?.usdzModel}`}
                                        // poster={`https://xrpanel.ariisco.com/assets/${product?.posterImage}`}
                                        shadow-intensity="1"
                                        camera-controls
                                        touch-action="pan-y"
                                        ar
                                        ar-modes="none"
                                        ios-ar-modes="none"
                                        ar-scale="auto"
                                    ></model-viewer>
                                    :
                                    <span>مدل یافت نشد</span>
                            }


                        </div>
                        <div className={Styled.ViewButton} onClick={() => startAR()}>
                            مشاهده در محیط
                        </div>
                        <div className={Styled.OtherProducts}>
                            {
                                selectCategory ?
                                    <>
                                        {
                                            selectCategory ?
                                                selectCategory?.[0]?.products?.map((item) => (
                                                    <CategoryCard data={item} onClick={() => handleProductDetail(item.id, item.products?.slug, item)} status={product.id == item.id ? true : false} />
                                                ))
                                                :
                                                null
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            productDetail ?
                                                productDetail?.products?.map((item) => (
                                                    <CategoryCard data={item} onClick={() => handleProductDetail(item.id, item.products?.[0]?.slug, item)} />
                                                ))
                                                :
                                                null
                                        }
                                    </>
                            }

                        </div>
                        <Footer />

                    </div>
            }


        </>
    )
};

export default ProductPage;
