import { useContext, useEffect, useState } from "react"
import Styled from "./TabBox.module.css"
import { TabContext } from "../../context"
import { useNavigate } from "react-router-dom"
import { ArrowLeft2 } from "iconsax-react"

const TabBox = (props) => {
    const { products, tab, setTab } = useContext(TabContext)
    const [category, setCategory] = useState()
    const navigate = useNavigate()


    useEffect(() => {
        console.log(products);
        const selectCategory = products?.data?.filter(item => item.level === 1);
        setCategory(selectCategory);
        setTab(selectCategory?.[1].id)
    }, [products]);




    const HandleTab = (id) => {
        navigate('/')
        setTab(id)


    }



    return (
        <>
            {
                props.id == 1 ?
                    <div className={Styled.TabBox2} onClick={() => navigate("/")}>
                        <ArrowLeft2 size="20" color="white" />
                        <span>بازگشت </span>
                    </div>
                    :
                    <div className={Styled.TabBox}>
                        {
                            category ?
                                category.map((item) => (
                                    <span onClick={() => HandleTab(item.id)} className={`${tab == item.id ? Styled.ActiveTab : Styled.Tab}`}>{item.name}</span>
                                ))
                                :
                                null
                        }
                    </div>
            }

        </>
    )
}

export default TabBox